:root,
[data-bs-theme="light"] {
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-color: #2367a0;
  --bs-border-width: 1.5px;
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-primary-text-emphasis: #052c65;
  --bs-primary-bg-subtle: #cfe2ff;
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 0.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.557129 0.111809C0.240129 0.27081 -0.00488281 0.605816 -0.00488281 1.01782V13.0178C-0.00488281 13.8418 0.961117 14.3248 1.62012 13.8298L9.62012 7.82983C10.1531 7.42983 10.1531 6.60581 9.62012 6.20581C8.62012 5.45581 2.62012 0.954804 1.62012 0.205804C1.29012 -0.0421966 0.875129 -0.0471907 0.557129 0.111809Z' fill='%232367A0'/%3E%3C/svg%3E%0A");
  --bs-accordion-btn-icon-width: 1.3rem;
  --bs-accordion-btn-icon-transform: rotate(-90deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.557129 0.111809C0.240129 0.27081 -0.00488281 0.605816 -0.00488281 1.01782V13.0178C-0.00488281 13.8418 0.961117 14.3248 1.62012 13.8298L9.62012 7.82983C10.1531 7.42983 10.1531 6.60581 9.62012 6.20581C8.62012 5.45581 2.62012 0.954804 1.62012 0.205804C1.29012 -0.0421966 0.875129 -0.0471907 0.557129 0.111809Z' fill='%232367A0'/%3E%3C/svg%3E%0A");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}
.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0px;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-flush .accordion-item {
  border-right: 0px;
  border-left: 0px;
  border-radius: 0px;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0px;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0px;
}

.accordion-header {
  margin-bottom: 0px;
}

.accordion-header:focus-visible {
  outline: none;
  box-shadow: none;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0px;
  border-radius: 0px;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  margin-right: 5px;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0px;
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0px;
}
.collapse:not(.show) {
  display: none;
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-flush .accordion-collapse {
  border-width: 0px;
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
