@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
/* overall page css */

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.content-section-spacing {
  padding-top: 5%;
  padding-bottom: 5%;
}

.content-section-padding {
  margin: 0 0%;
}

.aim h2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #000b33;
  font-weight: bold;
  opacity: 100%;
}

.myHeader h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #000b33;
  font-weight: bold;
}

.blueHeader h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #006dae;
  font-weight: bold;
}

.myHeader p {
  color: #94959b;
}

.aim p {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #4d5470;
  font-weight: 300;
}

.character-rive {
  height: 50vw;
}

/* sneak peak video*/
.video-container {
  height: 0;
  margin-bottom: 1em;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}

.video-container {
  margin: 0 -1rem;
  box-shadow: 0px 15px 30px 0px rgba(70, 70, 70, 0.10);
}

@media only screen and (min-width: 60em) {
  .video-container {
    margin: 0;
  }
}

.video-container {
  border-radius: 40px;
  box-shadow: 0px 15px 30px 0px rgba(70, 70, 70, 0.10);
}

div.video-container {
  text-align: center;
}

.video-container iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* ----- sneak peak video end ----*/

/* gamified experice start*/
.gamified {
  height: 550px !important;
  background-color: #edf7ff;
  overflow: hidden;
}

.phone-container {
  position: relative;
  max-height: 700px;
}

.gamified-img {
  transform: rotate(-15deg);
  width: 65%;
  max-width: 400px;
  position: absolute;
  overflow-x: auto;
  top: 10%;
  left: 36%;
}

.section-row-content {
  height: 400px;
}

.choose-your-style {
  width: 80%;
  overflow-x: auto;
  margin-top: 8%;
}

.choose-your-style-heading>h2 {
  height: 25% !important;
}

.btn:hover {
  color: #ffffff;
  text-decoration: none;
  border: none;
}

.card {
  height: 92%;
}

/* gamified experice end*/

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 280px) {
  .character-rive {
    height: 50vw;
  }

  .section-row-content {
    height: 450px;
  }

  .choose-your-style {
    width: 80%;
    overflow-x: auto;
    margin-top: 30%;
  }

  .choose-your-style-pinkCircleImage {
    position: absolute;
    width: 20%;
    top: 190px;
    right: 20px;
  }

  .understand-yourself-better {
    position: relative;
    width: 80%;
    overflow-x: auto;
    margin-top: 2%;
  }

  .understand-yourself-better-MoodGraphImage {
    position: absolute;
    top: 28%;
    left: 0%;
    width: 35%;
  }

  .understand-yourself-better-happyWidgetImage {
    position: absolute;
    top: 0%;
    right: -5%;
    width: 50%;
  }

  .burst-focus-mindfulnessImage {
    position: relative;
    margin-top: 13%;
    right: -24%;
    width: 55%;
  }

  .breath-fire-breathworksImage {
    position: absolute;
    top: 30%;
    right: 32%;
    width: 55%;
  }

  .darkmodeImage-text {
    height: 25%;
  }

  .darkmodeImage {
    width: 100%;
    height: 60vh;
    position: relative;
    margin-top: 2%;
  }

  .storeButtons {
    width: 90%;
    max-width: 350px;
  }

  .video-container {
    border-radius: 0px;
    box-shadow: 0px 15px 30px 0px rgba(70, 70, 70, 0.10);
  }
}

@media only screen and (min-width: 320px) {
  .character-rive {
    height: 50vw;
  }

  .section-row-content {
    height: 400px;
  }

  .choose-your-style {
    width: 70%;
    overflow-x: auto;
    margin-top: 14%;
  }

  .choose-your-style-pinkCircleImage {
    position: absolute;
    width: 16%;
    top: 170px;
    right: 40px;
  }

  .understand-yourself-better {
    position: relative;
    width: 46%;
    overflow-x: auto;
    margin-top: 5%;
  }

  .understand-yourself-better-MoodGraphImage {
    position: absolute;
    top: 28%;
    left: 15%;
    width: 26%;
  }

  .understand-yourself-better-happyWidgetImage {
    position: absolute;
    top: 2%;
    right: 8%;
    width: 40%;
  }

  .burst-focus-mindfulnessImage {
    position: relative;
    margin-top: 13%;
    right: -22%;
    width: 50%;
  }

  .breath-fire-breathworksImage {
    position: absolute;
    top: 30%;
    right: 35%;
    width: 50%;
  }

  .darkmodeImage-text {
    height: 25%;
  }

  .darkmodeImage {
    width: 100%;
    height: 40vh;
    position: relative;
    margin-top: 4%;
  }

  .video-container {
    border-radius: 0px;
  }
}

@media only screen and (min-width: 375px) {
  .character-rive {
    height: 50vw;
  }

  .section-row-content {
    height: 400px;
  }

  .choose-your-style {
    width: 55%;
    overflow-x: auto;
    margin-top: 13%;
  }

  .choose-your-style-pinkCircleImage {
    position: absolute;
    width: 14%;
    top: 160px;
    right: 70px;
  }

  .understand-yourself-better {
    position: relative;
    width: 46%;
    overflow-x: auto;
    margin-top: -5%;
  }

  .understand-yourself-better-MoodGraphImage {
    position: absolute;
    top: 25%;
    left: 15%;
    width: 26%;
  }

  .understand-yourself-better-happyWidgetImage {
    position: absolute;
    top: -8%;
    right: 8%;
    width: 40%;
  }

  .burst-focus-mindfulnessImage {
    position: relative;
    margin-top: 13%;
    right: -12%;
    width: 40%;
  }

  .breath-fire-breathworksImage {
    position: absolute;
    top: 30%;
    right: 45%;
    width: 40%;
  }

  .darkmodeImage-text {
    height: 25%;
  }

  .darkmodeImage {
    width: 100%;
    height: 45vh;
    position: relative;
    margin-top: 4%;
  }

  .video-container {
    border-radius: 0px;
  }
}

@media only screen and (min-width: 425px) {
  .character-rive {
    height: 50vw;
  }

  .section-row-content {
    height: 450px;
  }

  .choose-your-style {
    width: 60%;
    overflow-x: auto;
    margin-top: 5%;
  }

  .choose-your-style-pinkCircleImage {
    position: absolute;
    width: 14%;
    top: 160px;
    right: 70px;
  }

  .understand-yourself-better {
    position: relative;
    width: 50%;
    overflow-x: auto;
    margin-top: -12%;
  }

  .understand-yourself-better-MoodGraphImage {
    position: absolute;
    top: 25%;
    left: 15%;
    width: 26%;
  }

  .understand-yourself-better-happyWidgetImage {
    position: absolute;
    top: -12%;
    right: 8%;
    width: 40%;
  }

  .burst-focus-mindfulnessImage {
    position: relative;
    margin-top: 13%;
    right: -12%;
    width: 40%;
  }

  .breath-fire-breathworksImage {
    position: absolute;
    top: 30%;
    right: 45%;
    width: 40%;
  }

  .darkmodeImage-text {
    height: 25%;
  }

  .darkmodeImage {
    width: 100%;
    height: 45vh;
    position: relative;
    margin-top: 0%;
  }

  .video-container {
    border-radius: 0px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .character-rive {
    height: 40vw;
  }

  .section-row-content {
    height: 550px;
  }

  .choose-your-style {
    width: 45%;
    overflow-x: auto;
    margin-top: 0%;
  }

  .choose-your-style-pinkCircleImage {
    position: absolute;
    width: 12%;
    top: 170px;
    right: 160px;
  }

  .understand-yourself-better {
    position: relative;
    width: 40%;
    overflow-x: auto;
    margin-top: -15%;
  }

  .understand-yourself-better-MoodGraphImage {
    position: absolute;
    top: 25%;
    left: 18%;
    width: 22%;
  }

  .understand-yourself-better-happyWidgetImage {
    position: absolute;
    top: -21%;
    right: 12%;
    width: 35%;
  }

  .burst-focus-mindfulnessImage {
    position: relative;
    margin-top: -2%;
    right: -12%;
    width: 45%;
  }

  .breath-fire-breathworksImage {
    position: absolute;
    top: 18%;
    right: 45%;
    width: 45%;
  }

  .darkmodeImage-text {
    height: 25%;
  }

  .darkmodeImage {
    width: 100%;
    height: 50vh;
    position: relative;
    margin-top: -2%;
  }

  .storeButtons {
    width: 50%;
    max-width: 350px;
  }

  .video-container {
    border-radius: 40px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .character-rive {
    height: 35vw;
  }

  .section-row-content {
    height: 550px;
  }

  .lock-img {
    position: absolute;
    width: 100%;
    max-width: 120px;
    left: 70%;
  }

  .hand-img {
    position: absolute;
    width: 100%;
    max-width: 150px;
    top: 25%;
    left: 5%;
  }

  .hat-img {
    position: absolute;
    width: 100%;
    max-width: 150px;
    bottom: 0;
    left: 18%;
  }

  .choose-your-style-heading {
    height: 50% !important;
  }

  .choose-your-style {
    width: 65%;
    overflow-x: auto;
  }

  .choose-your-style-pinkCircleImage {
    position: absolute;
    width: 15%;
    top: 190px;
    right: 90px;
  }

  .understand-yourself-better {
    position: relative;
    width: 65%;
    overflow-x: auto;
    margin-top: -5%;
  }

  .understand-yourself-better-MoodGraphImage {
    position: absolute;
    top: 55%;
    left: 5%;
    width: 30%;
  }

  .understand-yourself-better-happyWidgetImage {
    position: absolute;
    top: -2%;
    right: 0%;
    width: 45%;
  }

  .burst-focus-mindfulnessImage {
    position: relative;
    margin-top: -10%;
    right: -16%;
    width: 50%;
  }

  .breath-fire-breathworksImage {
    position: absolute;
    top: 16%;
    right: 40%;
    width: 50%;
  }

  .darkmodeImage-text {
    height: 80%;
  }

  .darkmodeImage {
    width: 100%;
    height: 60vh;
    position: relative;
    margin-top: -4%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .character-rive {
    height: 25vw;
  }

  .section-row-content {
    height: 550px;
  }

  .gamified {
    height: 700px !important;
  }

  .lock-img {
    position: absolute;
    width: 100%;
    max-width: 150px;
    left: 60%;
  }

  .hand-img {
    position: absolute;
    width: 100%;
    max-width: 200px;
    top: 25%;
    left: 5%;
  }

  .hat-img {
    position: absolute;
    width: 100%;
    max-width: 200px;
    bottom: 0;
    left: 18%;
  }

  .choose-your-style {
    width: 55%;
    overflow-x: auto;
    margin-top: -5%;
  }

  .choose-your-style-pinkCircleImage {
    position: absolute;
    width: 15%;
    top: 190px;
    right: 110px;
  }

  .understand-yourself-better {
    position: relative;
    width: 55%;
    overflow-x: auto;
    margin-top: -10%;
  }

  .understand-yourself-better-MoodGraphImage {
    position: absolute;
    top: 55%;
    left: 10%;
    width: 30%;
  }

  .understand-yourself-better-happyWidgetImage {
    position: absolute;
    top: -10%;
    right: 0%;
    width: 45%;
  }

  .burst-focus-mindfulnessImage {
    position: relative;
    margin-top: -2%;
    right: -21%;
    width: 45%;
  }

  .breath-fire-breathworksImage {
    position: absolute;
    top: 16%;
    right: 35%;
    width: 45%;
  }

  .darkmodeImage-text {
    height: 50%;
  }

  .darkmodeImage {
    width: 100%;
    height: 45vh;
    position: relative;
    margin-top: -4%;
  }
}

@media only screen and (min-width: 2500px) {

  .character-rive {
    height: 16vw;
  }

  .storeButtons {
    width: 30%;
    max-width: 350px;
  }

  .darkmodeImage-text {
    height: 65%;
  }

  .darkmodeImage {
    width: 100%;
    height: 45vh;
    position: relative;
    margin-top: -4%;
  }
}

.text-highlighted {
  color: #1f97de;
}

.text-secondary {
  color: #5d7288;
}

/* Navigation */

.navbar {
  padding: 0;
}

.menu-item {
  color: #111 !important;
  display: block;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 1rem 0;
  background-color: #fff;
  padding-left: 1.2rem !important;

}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 1);
  border-color: #00000000;
}

/* .navbar-toggler {
  padding: 0 !important;
} */

@media (max-width: 768px) {
  .menu-item:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
}

.navbar-brand {
  padding: 1.3em 0;
}

/* Navigation */

/* hero */

.heroImage {
  position: relative;
  width: 100%;
  overflow: visible;
  height: 100%;
}

.noClip {
  overflow: visible;
}

.heroImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3ClinearGradient id=%22b%22 gradientTransform=%22rotate(75 .5 .5)%22%3E%3Cstop offset=%220%25%22 stop-color=%22%23EA8B1E%22%2F%3E%3Cstop offset=%22100%25%22 stop-color=%22%23fd3952%22%2F%3E%3C%2FlinearGradient%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M834.5 612Q724 724 612 803t-257 33q-145-46-187-191t16-274q58-129 187-157t297-39q168-11 222.5 157t-56 280Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22url(%23b)%22 d=%22M834.5 612Q724 724 612 803t-257 33q-145-46-187-191t16-274q58-129 187-157t297-39q168-11 222.5 157t-56 280Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  z-index: -1;
  overflow: visible;
}

.heroPhone {
  z-index: 2;
}

/* stats */

.statsRow {
  background: linear-gradient(136deg, #1f97de 4.85%, #006dae 58.28%);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 20px 10px 20px 50px;
  /* width: 85% !important; */
}

.statsRow p,
.statsRow h1 {
  color: #fff;
}

/* stats */

/* cards */
.card-image {
  height: 50px;
  object-fit: contain;
  margin: 20px 0;
}

.customCard {
  box-shadow: 0px 15px 30px 0px rgba(70, 70, 70, 0.1) !important;
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.customCardText {
  color: #4d5470;
  opacity: 80%;
}

/* preview blocks in the center */

/* .tilesSection {
  margin: 0 5% !important;
} */

.preview {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  overflow: hidden;
}

.blue {
  background-color: #edf7ff;
}

.green {
  background-color: #f4fff9;
}

.red {
  background-color: #fdf7f7;
}

.dark {
  background-color: #444257;
}

.blue h2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #2367a0;
  font-weight: bold;
}

.green h2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #0e6c36;
  font-weight: bold;
}

.red h2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #c01219;
  font-weight: bold;
}

.dark h2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #fff;
  font-weight: bold;
}

.dark p {
  color: #fff !important;
}

p {
  color: #5d7288;
  font-size: 0.9rem;
}

/* results*/
.results {
  height: 50vh !important;
}

.resultText {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #000b33;
  font-weight: 500;
  color: var(--blueText, #2367a0);
}

/* results*/

/* footer */
.footer {
  background-color: #444257;
}

.footer p,
.footer a {
  color: #fff;
}

.graphite-intro {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.6em;
}

.text--footer {
  color: #969696;
  padding-top: 1rem;
}

.text--respects {
  color: #c2c2c2;
  margin: 0 auto;
  max-width: 800px;
  padding-bottom: 1rem;
}

/* footer */

/* Cicular progress bar start*/
.CircularProgressbar-trail {
  stroke: rgb(255 255 255) !important;
}

.btn-research-snapshot {
  color: #fff;
  border-radius: 5px;
  background: var(--blueGradient, linear-gradient(136deg, #1f97de 4.85%, #006dae 58.28%));
  padding: 1% 5%;
}

/* Cicular progress bar end*/

/* much more start*/

.much-more-img-container {
  position: absolute;
  height: 500px;
  max-height: 35vw;
  width: 100%;
}

.breathing-img {
  position: absolute;
  max-width: 15%;
  left: 12%;
}

.purpleBall-img {
  position: absolute;
  max-width: 4%;
  right: 24%;
  bottom: 8%;
}

.wink-img {
  position: absolute;
  max-width: 10%;
  right: 6%;
}

/* much more end */