@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
/* @import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; */
:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #455560;
  --txt-white: #fff;
  --main-color: #349eff;
  --second-color: #62b4ff;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-light: #ffffff;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-color-blue: #349eff;
  --second-color-blue: #62b4ff;

  --main-color-red: #fb0b12;
  --second-color-red: #ff4a6b;

  --main-color-cyan: #10d4d2;
  --second-color-cyan: #2ae9e6;

  --main-color-green: #019707;
  --second-color-green: #4caf50;

  --main-color-orange: #d68102;
  --second-color-orange: #fca11a;

  --sidebar-width: 300px;
  --border-radius: 15px;
  --topnav-height: 110px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: var(--main-color);
}
button {
  cursor: pointer;
  border: 0;
  outline: 0;
  color: var(--txt-color);
}

ul {
  list-style-type: none;
}

input {
  border: 2px solid transparent;
  outline: 0;
}

input:focus {
  border: 2px solid var(--main-color);
}

.card {
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

/* 
body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.landingpng {
  width: 100%;
} */

/* .Content-Editor {
  width: 50%;
  position: relative;
  float: left;
}

.dash-container {
  top: 200px;
  position: relative;
} */

/* .page-header {
  margin-bottom: 40px;
  text-transform: capitalize;
} */

/* .full-height {
  height: 100%;
}

.card.full-height {
  height: calc(100% - 30px);
}

.card__header {
  text-transform: capitalize;
}

.card > div ~ div {
  margin-top: 30px;
}

.card__footer {
  text-align: center;
  text-transform: capitalize;
} */

/* .light-background {
  background-color: var(--main-bg-light);
  color: #000;
}

.dark-background {
  background-color: var(--main-bg-dark);
  color: #fff;
}

.blue-color {
  background-color: var(--main-color-blue);
  color: #fff;
}

.red-color {
  background-color: var(--main-color-red);
  color: #fff;
}

.cyan-color {
  background-color: var(--main-color-cyan);
  color: #fff;
}

.green-color {
  background-color: var(--main-color-green);
  color: #fff;
} */
/* 
.orange-color {
  background-color: var(--main-color-orange);
  color: #fff;
}

.readingTimeForm {
  width: 100%;
  align-items: center !important;
}
.readingFormDropdown {
  width: 50%;
  margin-left: 25%;
}
.input-group {
  width: 100%;
  align-content: center !important;
  align-items: center;
}

.oneThridDiv {
  margin-top: 1%;
  width: 33%;
  float: left;
  padding: 0.5%;
}

.threeThridDiv {
  min-height: max-content;
  position: relative;
  box-shadow: var(--box-shadow);
  border-radius: 1px;
}
.categoriesDiv {
  display: inline-block;
  width: 100%;
  position: relative;
  border-radius: 1px;
  box-shadow: var(--box-shadow);
  padding: 1%;
} */

/* .Editor {
  border: 1px solid #ccc;
  border-radius: 2px;
  min-height: 200px;
  padding: 8px;
}

.cardItem {
  display: inline-flex;
}
.cardItem Button {
  height: min-content;
}
div.trim {
  max-height: 60px !important;

  overflow: hidden;
}

.trim img {
  max-height: 50px !important;

  overflow: hidden;
}

.trim img:hover {
  transform: scale(2);
  transition: 0.4s;
  overflow: auto;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .bx {
  font-size: 20px;
}

.bx-arrow-from-bottom {
  font-size: 20px;
}
.bx-user-check {
  font-size: 20px;
}

.bx-no-entry {
  font-size: 25px;
}

.paginationItem {
  background: #fff;
  border: 2px solid #666;
  padding: 10px 15px;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prev,
.next {
  background: #fff;
  border: none;
  padding: 10px;
  color: blue;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 10px;
  cursor: pointer;
} */
/* 
.paginationItem.active {
  border: 1px solid #888;
  color: rgb(19, 19, 19);
  pointer-events: none;
}

.paginationItem.active {
  color: rgb(39, 31, 31);
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}

@media screen and (max-width: 480px) {
  .landingpng {
    /* src: ${require("../../assets/images/Landing_tiny.png")}; 
  }
}



@media screen and (max-width: 700px) {
  .landingpng {
    display: none;
  }
}
*/
